<template>
  <h3 class="subtitle">
    {{ $gettext("Find an existing account") }}
  </h3>
  <FormKit
    v-if="!formSubmitted"
    id="accountSearchForm"
    type="form"
    :submit-label="$gettext('Search')"
    :submit-attrs="{ inputClass: 'button is-primary' }"
    @submit="accountSearch"
  >
    <FormKitSchema :schema="accountSearchSchema" />
  </FormKit>
  <div
    v-if="formSubmitted"
    id="search-results"
    class="block"
  >
    <AccountToLink
      v-for="account in accountSearchResults"
      :key="account.id"
      :account="account"
      :project-cmi-id="projectCmiId"
      :site-cmi-id="siteCmiId"
      @account-linked.once="resetAccountSearchForm(true)"
    />
    <template v-if="!accountSearchResults.length">
      <p>{{ $gettext("No results found") }}</p>
    </template>
    <a
      class="text-link"
      @click="resetAccountSearchForm(false)"
    >
      {{ $gettext("Search again") }}
    </a>
  </div>
</template>
<script lang="ts" setup>
  // Show search interface and results for accounts to link to a Site *or* Project.
  import { ref, nextTick } from "vue"
  import { reset as resetForm } from "@formkit/core"
  import { gettext } from "@/utils/Translation"
  import AccountToLink from "@/components/accounts/AccountToLink.vue"

  const { $gettext } = gettext
  const emit = defineEmits(["refreshAccountList"])
  const props = defineProps({
    // Search function should be an async function expecting one arg: a token
    searchFunction: {
      type: Function,
      required: true,
    },
    // One of the following two must be supplied.
    projectCmiId: {
      type: Number,
      default: null,
    },
    siteCmiId: {
      type: Number,
      default: null,
    },
  })
  const formSubmitted = ref(false)
  const accountSearchResults = ref(null)
  const accountSearchSchema = {
    $formkit: "text",
    name: "token",
    label: $gettext("Search accounts"),
    help: $gettext(
      "Search among existing Count Me In accounts by first name, family name, or username.",
    ),
  }

  async function accountSearch(data: { token: string }) {
    accountSearchResults.value = await props.searchFunction(data.token)
    formSubmitted.value = true
    await nextTick()
    document.getElementById("search-results").scrollIntoView({ behavior: "smooth" })
  }

  async function resetAccountSearchForm(newAffilition=false) {
    resetForm("accountSearchForm")
    accountSearchResults.value = null
    formSubmitted.value = false
    if (newAffilition) {
      // Refresh the list, now that we have a new member.
      emit("refreshAccountList")
    }
  }

</script>
<style lang="scss" scoped>

</style>
