<template>
  <ReLoginModal
    v-if="showReLoginModal"
    @login-complete="handleReLogin"
  />

  <slot name="header" />

  <h1 class="title">
    {{ pageTitle }}
  </h1>

  <slot name="formPreface" />

  <FormKit
    v-if="formIsEnabled"
    type="form"
    :submit-label="$gettext('Save')"
    :submit-attrs="{ inputClass: 'button is-primary' }"
    @submit="submitForm"
  >
    <FormKitSchema
      :schema="schema"
      :data="formData"
    />
  </FormKit>

  <slot name="formPostface" />
</template>
<script lang="ts" setup>
  import { ref } from "vue"
  import { useRouter } from "vue-router"
  import * as Sentry from "@sentry/browser"

  import { gettext } from "@/utils/Translation"
  import { handlePendingUploads } from "@/services/Upload"
  import { showFlashMessage } from "@/utils/GlobalState"
  import ReLoginModal from "@/components/accounts/ReLoginModal.vue"

  const { $gettext } = gettext
  const showReLoginModal = ref(false)
  const router = useRouter()
  let newItemLocalId = null // Once an object is created, override this.
  const props = defineProps({
    pageTitle: {
      type: String,
      required: true,
    },
    // Exactly one of successRoute and pushSuccessRouteFunc must be supplied
    // Needs to resolve to a route
    successRoute: {
      type: Object,
      default: null,
    },
    pushSuccessRouteFunc: {
      type: Function,
      default: null,
    },
    // Needs to be a FormKit schema
    schema: {
      type: Object,
      required: true,
    },
    // Optional: "initial" values to populate the form
    formData: {
      type: Object,
      default: () => ({}),
    },
    // Optional function to modify form data before passing on to saving functions
    mungeFormDataFunc: {
      type: Function,
      default: (data: unknown) => data,
    },
    // The function that handles local persistence. This function should return
    // a Promise that resolves to the saved item's local id.
    localSaveFunc: {
      type: Function,
      required: true,
    },
    // This is extremely hacky, but in cases where we're abusing the idea of the
    // form, we can suppress it (rather, its submit button) unless a criterion is met.
    formIsEnabled: {
      type: Boolean,
      default: true,
    }
  })

  // Called after the save process succeeded
  function saveSuccess(showSuccessMessage = true) {
    if (showSuccessMessage) {
      showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
    }
    if (props.successRoute) {
      router.push(props.successRoute)
    }
    else if (props.pushSuccessRouteFunc) {
      props.pushSuccessRouteFunc(newItemLocalId)
    }
    // I guess we're not going anywhere!
  }

  // Called after user successfully logs in, if needed (and with the same account!)
  function handleReLogin() {
    showReLoginModal.value = false
    saveSuccess()
    handlePendingUploads() // async
  }

  function submitForm(data) {
    this.submitAttrs.inputClass = "button is-loading"
    this.submitLabel = $gettext("Loading")
    if (props.mungeFormDataFunc) {
      props.mungeFormDataFunc(data)
    }
    props.localSaveFunc(data).then((localId: number) => {
      newItemLocalId = localId
      handlePendingUploads()
        .then(saveSuccess)
        .catch(error => {
          if (error.name === "LOGIN_REQUIRED") {
            showReLoginModal.value = true
          }
          else if (error.name === "CONNECTIVITY_REQUIRED" || error.name === "SERVER_DOWN") {
            const msg = $gettext("Your changes have been saved, but not uploaded. They will be uploaded the next time you are online.")
            showFlashMessage({ msg, class: "is-success" })
            saveSuccess(false)
          }
          else {
            Sentry.captureException(error)
            const msg = $gettext("Something went wrong unexpectedly when trying to save your changes. The details have been recorded for troubleshooting. You may try to save again, if you like.")
            showFlashMessage({ msg, class: "is-warning" })
          }
        })
    })
  }
</script>
