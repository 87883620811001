<!-- From https://www.iconfinder.com/icons/3669359/description_ic_icon -->
<template>
  <svg
    width="100%"
    height="100%"
    class="icon-description"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 4H12C9.79 4 8.02 5.79 8.02 8L8 40c0 2.21 1.77 4 3.98 4H36c2.21 0 4-1.79 4-4V16L28 4zm4 32H16v-4h16v4zm0-8H16v-4h16v4zm-6-10V7l11 11H26z"
    />
  </svg>
</template>
