<template>
  <section
    v-if="isTabletOrWider"
    class="tablet-plus"
  >
    <h2>{{ $gettext("Referrals") }}</h2>
    <template
      v-for="referral in referrals"
      :key="referral.contentId"
    >
      <ReferralMessage :referral="referral" />
    </template>
    <div
      v-if="!referrals?.length"
      class="muted is-italic"
    >
      {{ $gettext("No referrals.") }}
    </div>
  </section>
  <StickyCard
    v-else
    extra-section-classes="referrals"
  >
    <template #title>
      {{ $gettext("Referrals") }}
    </template>
    <template #cardContent>
      <ul
        v-if="referrals.length"
        class="list referral-list"
      >
        <li
          v-for="referral in referrals"
          :key="referral.id"
        >
          {{ referral.content }}
        </li>
      </ul>
      <div
        v-else
        class="muted is-italic referral-list"
      >
        {{ $gettext("No referrals.") }}
      </div>
    </template>
  </StickyCard>
</template>
<script lang="ts" setup>
  import { computed } from "vue"
  import { useWindowSize } from '@vueuse/core'

  import StickyCard from "@/components/assessments/StickyCard.vue"
  import ReferralMessage from "@/components/assessments/ReferralMessage.vue"
  const { width } = useWindowSize()
  const tabletMinWidth = 625 // Should correspond with $tablet defined in _main.css
  const isTabletOrWider = computed(() => width.value > tabletMinWidth)

  interface Props {
    referrals: Array<{ content: string, id: number }>
  }

  defineProps<Props>()
</script>
