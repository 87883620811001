<script lang="ts" setup>
  import { ref } from "vue"

  import {
    getCachedChildrenForSite,
    updateChildrenFromServer,
  } from "@/services/Child"
  import {
    getLastUpdateInfo,
    areCachedDataStale,
    showFlashMessage,
    setStalenessNotificationTime,
  } from "@/utils/GlobalState"
  import useServerRefresher from "@/composables/useServerRefresher"
  import { gettext } from "@/utils/Translation"

  import ContentRefresher from "@/components/ContentRefresher.vue"
  import LastSynced from "@/components/LastSynced.vue"
  import PageLoading from "@/components/PageLoading.vue"

  const { $gettext } = gettext
  const { refreshUnderway, serverRefresher } = useServerRefresher()

  const pageReady = ref(false)
  const children = ref([])
  const filteredChildren = ref([])
  const search = ref("")
  const lastSynced = ref(undefined)
  const props = defineProps({
    site: {
      type: Object,
      required: true,
    },
    discharged: {
      type: Boolean,
      default: false,
    },
  })
  const siteCmiId = props.site.cmiId
  const staleArgs = {
    type: props.discharged ? "siteChildrenDischarged" : "siteChildren",
    localItemId: props.site.id,
  }
  const staleNotificationQueryKey = props.discharged ? `siteChildrenDischarged-${props.site.id}` : `siteChildren-${props.site.id}`

  async function getChildrenFromServer(wasManuallyTriggered = false) {
    const args = [siteCmiId]
    if (props.discharged) {
      args.push(true)
    }
    await serverRefresher(updateChildrenFromServer, args, staleNotificationQueryKey, wasManuallyTriggered)
      .catch(async error => {
        if (error.name === "CONNECTIVITY_REQUIRED") {
          lastSynced.value = await getLastUpdateInfo(staleArgs)
          const msg = $gettext("You do not appear to be online.")
          let details = ""
          if (lastSynced.value) {
            details = $gettext("The list shown here was last updated %{ date }.", { date: lastSynced.value })
          }
          else {
            details = $gettext("The children from this site have never been updated from the server.")
          }
          showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
          setStalenessNotificationTime(staleNotificationQueryKey)
        }
        else {
          throw error
        }
      })
    await updateChildrenList()
  }

  async function getData() {
    if (await areCachedDataStale(staleArgs)) {
      await getChildrenFromServer()
    }
    else {
      await updateChildrenList()
    }
    pageReady.value = true
  }

  async function updateChildrenList() {
    children.value = await getCachedChildrenForSite(siteCmiId, { dischargedOnly: props.discharged })
    search.value = ""
    filteredChildren.value = children.value
    lastSynced.value = await getLastUpdateInfo(staleArgs)
  }

  const handleSearch = (e) => {
    search.value = e.target.value

    if (search.value.length) {
      const searchWords = search.value.toLowerCase().split(" ")
      filteredChildren.value = children.value.filter((child) => {
        if (child.cmi === search.value.trim()) return true

        const childWords = child.fullName.toLowerCase().split(" ")
        return searchWords.every((searchWord) =>
          childWords.some((childWord) => childWord.includes(searchWord)),
        )
      })
    } else {
      filteredChildren.value = children.value
    }
  }

  getData()
</script>
<template>
  <form class="panel-block">
    <div class="control is-flex">
      <input
        class="input mb-0"
        type="search"
        :placeholder="$gettext('Search')"
        :value="search"
        @input="handleSearch"
      />
      <div class="ml-4">
        <ContentRefresher
          :refresh-function="getChildrenFromServer"
          :refresh-underway="refreshUnderway"
        />
      </div>
    </div>
    <p class="muted listing-count">
      {{ $ngettext("Showing %{ number } child of %{ total }.", "Showing %{ number } children of %{ total }.", filteredChildren.length, { number: filteredChildren.length, total: children.length }) }}
    </p>
  </form>

  <template v-if="pageReady">
    <template v-if="filteredChildren.length">
      <div
        v-for="child in filteredChildren"
        :key="child.id"
        class="panel-block"
      >
        <div
          v-if="child.createdDuringTraining"
          class="training-mode-label"
        >
          {{ $gettext("Created During Training") }}
        </div>
        <strong>{{ child.fullName }}</strong>
        <div class="child-stats">
          <div class="info-block">
            <strong class="muted">
              {{ $gettext("Count Me In ID") }}
            </strong> {{ child.cmiId || "-" }}
            <br />
            <strong class="muted">
              {{ $gettext("Sex") }}
            </strong> {{ child.sexDisplay }}
          </div>
          <div class="info-block">
            <strong class="muted">
              {{ $gettext("Current age") }}
            </strong> {{ child.getNiceAge() }}
            <br />
            <strong class="muted">
              {{ $gettext("Date of birth") }}
            </strong> {{ child.dob?.toLocaleDateString() || $gettext("Unknown") }}
          </div>
        </div>
        <ul class="child-actions">
          <li>
            <router-link
              class="text-link"
              :to="{ name: 'ChildUpdate', params: { childId: child.id, siteCmiId } }"
            >
              {{ $gettext("Edit") }}
            </router-link>
          </li>
          <li v-if="!discharged">
            <router-link
              class="text-link"
              :to="{ name: 'ChildDischarge', params: { childId: child.id, siteCmiId } }"
            >
              {{ $gettext("Discharge") }}
            </router-link>
          </li>
          <li>
            <router-link
              class="text-link"
              :to="{ name: 'ChildCarePlan', params: { childId: child.id } }"
            >
              {{ $gettext("Care Plan") }}
            </router-link>
          </li>
        </ul>
      </div>
    </template>

    <p
      v-else
      class="p-4 muted is-italic"
    >
      <template v-if="search">
        {{ $gettext("No children match this search") }}
      </template>
      <template v-else-if="discharged">
        {{ $gettext("No children have been discharged from this site yet.") }}
      </template>
      <template v-else>
        {{ $gettext("No children have been added to this site yet.") }}
      </template>
    </p>
    <LastSynced
      :last-synced="lastSynced"
      :in-panel="true"
    />
  </template>
  <PageLoading
    v-else
    wrapper-classes="padded"
  />
</template>
<style scoped>
  .panel-block .control {
    align-items: center;
  }
  .panel-block {
    display: block;
    padding: 1em 0.75em;
  }
  /* This width correspond with $tablet */
  @media (min-width: 625px) {
    .panel-block {
      padding: 1.25em;
    }
  }

  .child-stats, .child-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .child-stats {
    font-size: 75%;
  }
  .child-actions {
    margin-top: 1em;
  }
  .listing-count {
    font-size: 75%;
    font-style: italic;
    margin: 0.5em 0 0;
  }
</style>
