<template>
  <section
    v-if="!includeOnWide || !isTabletOrWider"
    :class="sectionClass"
  >
    <header class="card-header">
      <h2>
        <slot name="title" />
      </h2>
    </header>
    <div class="card-content">
      <slot name="cardContent" />
    </div>
  </section>
  <section
    v-else
    class="tablet-plus"
  >
    <h2>
      <slot name="title" />
    </h2>
    <slot name="cardContent" />
  </section>
</template>
<script setup>
  import { computed } from "vue"
  import { useWindowSize } from "@vueuse/core"

  const { width } = useWindowSize()
  const tabletMinWidth = 625 // Should correspond with $tablet defined in _main.css
  const isTabletOrWider = computed(() => width.value > tabletMinWidth)

  const props = defineProps({
    extraSectionClasses: {
      type: String,
      default: "",
    },
    // Should content also be presented as a non-sticky <section>?
    includeOnWide: {
      type: Boolean,
      default: false,
    },
  })

  const sectionClass = `sticky-card card ${props.extraSectionClasses}`
</script>
<style scoped>
  .card-header {
    box-shadow: none;
    border-bottom: 1px solid rgba(10, 10, 10, 0.1); /* from $shadow (rgba($scheme-invert, 0.1)) */
  }
  .card-header-icon {
    padding: 0.5em 0 0.5em 1em;
  }
  .card-header-title {
    padding: 0.5em 0 0.5em 0.5em;
  }
  .card-header-icon + .card-header-title {
    padding-left: 0.25em;
  }
  .card-content {
    padding: 0;
    color: hsl(0, 0%, 48%); /* $grey */
    font-size: 85%;
  }
</style>
