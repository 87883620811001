<script lang="ts" setup>
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

defineProps({
  site: {
    type: Object,
    required: true,
  },
  date: {
    type: Date,
    required: true,
  },
  assessments: {
    type: Array<object>,
    required: true,
  },
  addSiteAssessment: {
    type: Boolean,
  },
})
function getAssessmentsForChild(date, child) {
  const {
    nextAnemiaAssessmentDate,
    nextEarlyidAssessmentDate,
    nextGrowthAssessmentDate,
    nextMealtimeAssessmentDate,
    nextBestPracticesAssessmentDate,
  } = child
  const assessments = []
  const modules = [
    { name: $gettext("Anemia"), value: nextAnemiaAssessmentDate, assessmentType: "a" },
    { name: $gettext("Developmental Screening"), value: nextEarlyidAssessmentDate, assessmentType: "d" },
    { name: $gettext("Growth"), value: nextGrowthAssessmentDate, assessmentType: "g" },
    { name: $gettext("Mealtime"), value: nextMealtimeAssessmentDate, assessmentType: "m" },
    { name: $gettext("Mealtime Best Practices"), value: nextBestPracticesAssessmentDate, assessmentType: "b" },
  ]
  modules.forEach((assessment) => {
    if (date.isSame(assessment.value, "day")) {
      assessments.push(assessment)
    }
  })
  return assessments
}

function compareByIdAsc(a, b) {
  return a.cmiId - b.cmiId
}
</script>

<template>
  <h2>{{ $gettext(`Assessments Due on %{ day }`, { day: date.format("MM/DD/YYYY") }) }}</h2>
  <ul>
    <li
      v-for="child in [...assessments[date.format('YYYY-MM-DD')]].sort(compareByIdAsc)"
      :key="child.cmiId"
    >
      {{ child.fullName }}
      <ul>
        <li
          v-for="assessment in getAssessmentsForChild(date, child)"
          :key="assessment.name"
        >
          <router-link :to="{ name: 'ChildConfirmation', params: { childId: child.id, assessmentType: assessment.assessmentType } }">
            {{ assessment.name }}
          </router-link>
        </li>
      </ul>
    </li>
    <li v-if="addSiteAssessment">
      <div class="btn-w-label">
        <strong>{{ $gettext("Site Assessment") }}</strong>
        <router-link
          :to="'/sites/' + site + '/assess/mealtime/'"
          class="button"
        >
          {{ $gettext("Start") }}
        </router-link>
      </div>
    </li>
  </ul>
</template>

<style scoped>
ul {
  list-style: disc;
}

ul > li {
  margin-left: 2em;
}
</style>
