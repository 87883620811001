<template>
  <div class="block">
    <template v-for="role in roles">
      <div
        v-if="role.value != account.roleId"
        :key="role.value"
        class="mb-3"
      >
        <button
          :class="{
            button: true,
            'is-primary': true,
            'is-loading': clickedButton == `change-${role.value}-${account.accountId}`,
          }"
          @click.prevent="changeRole(account, role.value)"
        >
          {{ role.changeLabel }}
        </button>
      </div>
    </template>
  </div>
  <div class="block">
    <button
      :class="{
        button: true,
        'is-primary': true,
        'delete-role': true,
        'is-loading': clickedButton == `remove-${account.accountId}`,
      }"
      @click.prevent="removeFromSite(account.accountId)"
    >
      {{ $gettext("Remove from site") }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { gettext } from "@/utils/Translation"
import { useRoute } from "vue-router"

import { showFlashMessage } from "@/utils/GlobalState"
import { removeAccountRoleFromSite, updateAccountRoleAtSite } from "@/services/Site"
import { getSiteRolesOptions } from "@/utils/Options"

const emit = defineEmits(["removeAccount"])
const { $gettext } = gettext
const route = useRoute()
const siteCmiId = parseInt(route.params.siteCmiId as string)

defineProps({
  account: {
    type: Object,
    required: true,
  },
})

const clickedButton = ref(null)
const roles = getSiteRolesOptions()
const roleMap = {}
roles.forEach(role => roleMap[role.value] = role.label)

async function removeFromSite(accountId) {
  if (confirm($gettext("Are you sure? This person will no longer be able to access this site."))) {
    clickedButton.value = `remove-${accountId}`
    await removeAccountRoleFromSite(accountId, siteCmiId).then(() => {
      emit("removeAccount", accountId)
      showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
    })
  }
}

async function changeRole(account, roleId) {
  clickedButton.value = `change-${roleId}-${account.accountId}`
  await updateAccountRoleAtSite(account.accountId, siteCmiId, roleId).then(() => {
    account.roleId = roleId
    account.roleLabel = roleMap[roleId]
    showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
  })
}
</script>

<style scoped>
.delete-role {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  width: 100%;
}
.account-controls .button {
  width: 100%;
}
</style>
