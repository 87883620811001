import { db, IProject } from "@/db"
import * as api from "@/services/Api"

/**
 * Return true or false whether we think the current user can add new sites
 * to the specified project. Expects a project object with a role prop.
 *
 * (Note: this is just to determine whether to show editing interface for
 * the site. It's up to the server to ultimately decided whether the user
 * has sufficient permissions.)
 * @args {object} with property role (object representing project)
 * @return [bool]
 */
export function canAddSite(project: { role: string }) {
  const addingRoles = ["staff", "project administrator", "project supporter"]
  return addingRoles.includes(project.role)
}

// See above.
export function canEditProject(project: { role: string }) {
  const addingRoles = ["staff", "project administrator", "project supporter"]
  return addingRoles.includes(project.role)
}

// Retrieve project by id, by default from local cached data, but optionally from server.
// If from server, we update the database with whatever the server returns.
// If withSites option is true, add a sites property that is an array of site objects.
export async function getProjectByCmiId(cmiId: number, options = { fromServer: false, withSites: false }) {
  let project = await db.projects.get({ cmiId })
  if (options.fromServer) {
    const localId = project?.id
    project = await api.getProject(cmiId)
    project.id = localId
    await db.projects.put(project)
  }
  if (options.withSites) {
    project["sites"] = await db.sites
      .where({ projectId: cmiId })
      .sortBy("name")
  }
  return project
}

// Retrieve all local projects
export async function getProjects() {
  return await db.projects.toArray()
}

export async function uploadProject(project: IProject) {
  return await api.uploadProject(project)
    .then(async function(data) {
      // If a successful POST, update the internal CMI id
      if (!project.cmiId) {
        project.cmiId = data.id
      }
      project.role = data.role
      await db.projects.put(project)
      return data
    })
}

export async function getProjectAccountList(projectCmiId: number) {
  return await api.getProjectAccountList(projectCmiId)
}

/**
 * Return an array (could be empty) of objects representing Accounts matching
 * the token. Token is compared to Accounts' first name, last name, and username.
 * The objects contain the following properties: name, id, username.
 *
 * @args {Number}  projectCmiId
 * @args {String}  token
 * @return [Array]
 */
export async function searchAccountsToLink(projectCmiId: number, token: string) {
  return await api.searchAccountsToLinkForProject(projectCmiId, token)
}

export async function linkAccountToProject(accountId: number, projectId: number, role: string) {
  return await api.linkAccountToProject(accountId, projectId, role)
}

export async function updateAccountRoleForProject(accountId: number, projectId: number, role: string) {
  return await api.updateAccountRoleForProject(accountId, projectId, role)
}

export async function removeAccountRoleFromProject(accountId: number, projectId: number, { retainSitePermissions }) {
  return await api.removeAccountRoleFromProject(accountId, projectId, retainSitePermissions)
}

export async function createProjectAccount(projectId: number, account: object) {
  return await api.createProjectAccount(projectId, account)
}
