<!-- From https://www.iconfinder.com/icons/352001/account_child_icon -->
<template>
  <svg
    aria-hidden="true"
    height="100%"
    width="100%"
    viewBox="0 0 48 48"
    class="icon-children"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 24c2.76 0 4.98-2.24 4.98-5s-2.22-5-4.98-5c-2.76 0-5 2.24-5 5s2.24 5 5 5zm-15-2c3.31 0 5.98-2.69 5.98-6s-2.67-6-5.98-6c-3.31 0-6 2.69-6 6s2.69 6 6 6zm15 6c-3.67 0-11 1.84-11 5.5v4.5h22v-4.5c0-3.66-7.33-5.5-11-5.5zm-15-2c-4.67 0-14 2.34-14 7v5h14v-4.5c0-1.7.67-4.67 4.74-6.94-1.74-.37-3.43-.56-4.74-.56z"
    />
  </svg>
</template>
