<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"

  import { getSiteByCmiId } from "@/services/Site"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  import ChildListPanel from "@/components/children/ChildListPanel.vue"
  import PageLoading from "@/components/PageLoading.vue"

  const route = useRoute()
  const siteCmiId = parseInt(route.params.siteCmiId as string)

  const currentTab = ref("current")
  const site = ref(null)
  const pageReady = ref(false)

  async function getData() {
    site.value = await getSiteByCmiId(siteCmiId)
    setCurrentViewContext(site, "site")
    pageReady.value = true
  }

  getData()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Look Up a Child") }}
    </h1>

    <section
      v-if="pageReady"
      class="panel is-primary"
    >
      <p class="panel-tabs">
        <a
          :class="{ 'is-active': currentTab == 'current' }"
          @click="currentTab = 'current'"
        >
          {{ $gettext("Current") }}
        </a>
        <a
          :class="{ 'is-active': currentTab == 'discharged' }"
          @click="currentTab = 'discharged'"
        >
          {{ $gettext("Discharged") }}
        </a>
      </p>
      <ChildListPanel
        v-if="currentTab === 'current'"
        :site="site"
        :discharged="false"
      />
      <ChildListPanel
        v-else
        :site="site"
        :discharged="true"
      />
    </section>
    <PageLoading v-else />
  </article>
</template>

<style scoped>
  p.panel-tabs {
    margin-bottom: 0
  }
</style>
