<template>
  <ExpandCollapse
    v-if="child"
    extra-class="child-header"
  >
    <template #label>
      <nav
        class="breadcrumb is-small"
        aria-label="breadcrumbs"
      >
        <ul>
          <li v-if="site">
            <router-link
              class="text-link"
              :aria-description="$gettext('Child\'s site')"
              :to="{ name: 'SiteHome', params: { siteCmiId: child.siteId }}"
            >
              {{ site.name }}
            </router-link>
          </li>
          <li class="is-active">
            <a aria-current="page">
              <strong :ariaDescription="$gettext('This is a page about %{page_topic}', { page_topic: child.fullName })">
                {{ child.fullName }}
              </strong>
              <span :ariaDescription="$gettext('Child\'s age')">
                {{ child.getNiceAge() }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </template>
    <template #content>
      <dl v-if="child">
        <dt>{{ $gettext("Sex") }}</dt>
        <dd>{{ child.sexDisplay }}</dd>
        <dt>{{ $gettext("Date of birth") }}</dt>
        <dd>{{ child.dob?.toLocaleDateString() }}</dd>
        <dt>{{ $gettext("Diagnoses") }}</dt>
        <dd>{{ child.diagnosesDisplay || $gettext("None") }}</dd>
        <dt>{{ $gettext("Count Me In Id") }}</dt>
        <dd>{{ child.cmiId ?? "-" }}</dd>
      </dl>
      <div
        v-if="child.contactInformation || child.caregiverPhoneNumber"
        class="contact-info"
      >
        <h3>
          {{ $gettext("Contact") }}
        </h3>
        <div class="columns is-mobile">
          <div
            v-if="child.contactInformation"
            class="column"
          >
            {{ child.contactInformation }}
          </div>
          <div
            v-if="child.caregiverPhoneNumber"
            class="column is-narrow"
          >
            <a
              :href="`tel:${child.caregiverPhoneNumber}`"
              :title="$gettext('Call this phone number')">
              <IconPhone />
              <span>{{ child.caregiverPhoneNumber }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="actions">
        <router-link
          class="text-link"
          :to="{ name: 'ChildCarePlan', params: { childId: child.id } }"
        >
          {{ $gettext("Care Plan") }}
        </router-link>
        <router-link
          class="text-link"
          :to="{ name: 'ChildUpdate', params: { childId: child.id, siteCmiId: child.siteId } }"
        >
          {{ $gettext("Edit this information") }}
        </router-link>
        <router-link
          class="text-link"
          :to="{ name: 'ChildDischarge', params: { childId: child.id, siteCmiId: child.siteId } }"
        >
          {{ $gettext("Discharge child") }}
        </router-link>
      </div>
    </template>
  </ExpandCollapse>
</template>

<script lang="ts" setup>
  import { ref } from "vue"

  import { Child } from "@/models/Child"
  import ExpandCollapse from "@/components/ExpandCollapse.vue"
  import IconPhone from "@/components/svg/IconPhone.vue"

  const props = defineProps({
    child: {
      type: Child,
      default: null,
    },
  })

  const site = ref(null)

  async function getData() {
    site.value = await props.child.getSite()
  }

  getData()

</script>
