<script setup>
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
</script>

<template>
  <div id="assessment-calendar-help">
    <h2 id="modalTitle">
      {{ $gettext("Help for This Page") }}
    </h2>
    <div class="help-content">
      <p class="page-description">
        {{ $gettext('The "Assessment Calendar" page helps you plan by showing when assessments are due.') }}
      </p>
      <dl>
        <dt>{{ $gettext("About the calendar") }}</dt>
        <dd>
          {{ $gettext("The days with assessments due show the number of assessments inside an orange circle.") }}
        </dd>
        <dd>
          {{ $gettext("You can tap or click on that number to see the names of the children who are due that day, and what types of assessments will be due.") }}
        </dd>
        <dd>
          {{ $gettext("Once you start an assessment, it will no longer be show up for that month, but for the next time an assessment is due for that child (which could be in one, three, or six months in the future).") }}
        </dd>
        <dd>
          {{ $gettext("The calendar is always up-to-date, and will adjust when follow-up assessments are added.") }}
        </dd>
        <dt>{{ $gettext("What is not shown?") }}</dt>
        <dd>
          {{ $gettext("The calendar doesn't include assessments that have been started but not finished.") }}
        </dd>
        <dd>
          {{ $gettext("It's also missing children who have never been assessed; those children should be assessed as soon as possible.") }}
        </dd>
      </dl>
    </div>
  </div>
</template>
