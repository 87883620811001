<script lang="ts" setup>
  import { useRoute } from "vue-router"
  import { ref } from "vue"

  import { gettext } from "@/utils/Translation"
  import { getChildById } from "@/services/Child"
  import { setCurrentViewContext  } from "@/utils/GlobalState"
  import { Child } from "@/models/Child"
  import { getSiteByCmiId } from "@/services/Site"
  import ChildHeader from "@/components/children/ChildHeader.vue"

  const route = useRoute()
  const childId = parseInt(route.params.childId)
  const { $gettext } = gettext
  const child = ref(null)
  const site = ref(null)
  const pageReady = ref(false)
  const ageInMonths = ref(0)

  async function getData() {
    child.value = new Child(await getChildById(childId))
    ageInMonths.value = child.value.getAgeInMonths()
    setCurrentViewContext(child, "child")
    site.value = await getSiteByCmiId(child.value.siteId)
    pageReady.value = true
  }

  getData()
</script>
<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Child added successfully") }}
    </h1>

    <template v-if="pageReady">
      <div class="block">
        <router-link
          :to="{ name: 'ChildCreate', params: { siteCmiId: site.cmiId } }"
          class="button is-primary is-fullwidth"
        >
          {{ $gettext("Add another child") }}
        </router-link>
      </div>

      <p class="muted">
        {{ $gettext("Or you may assess this child immediately.") }}
      </p>

      <div class="columns buttons">
        <div
          v-if="site.growthEnabled"
          class="column"
        >
          <router-link
            :to="{ name: 'ChildConfirmation', params: { siteCmiId: site.cmiId, childId, assessmentType: 'g' } }"
            class="button is-primary is-fullwidth"
          >
            {{ $gettext("Growth") }}
          </router-link>
        </div>
        <div
          v-if="site.isFeedingScreeningEnabled"
          class="column"
        >
          <router-link
            :to="{ name: 'ChildConfirmation', params: { siteCmiId: site.cmiId, childId, assessmentType: 'f' } }"
            class="button is-primary is-fullwidth"
          >
            {{ $gettext("Feeding Screening") }}
          </router-link>
        </div>

        <div
          v-if="site.isChildMealtimeBestPracticeAssessmentEnabled && ageInMonths < 6 * 12"
          class="column"
        >
          <router-link
            :to="{ name: 'ChildConfirmation', params: { siteCmiId: site.cmiId, childId, assessmentType: 'b' } }"
            class="button is-primary is-fullwidth"
          >
            {{ $gettext("Best Practices") }}
          </router-link>
        </div>

        <div
          v-if="site.anemiaEnabled"
          class="column"
        >
          <router-link
            :to="{ name: 'ChildConfirmation', params: { siteCmiId: site.cmiId, childId, assessmentType: 'a' } }"
            class="button is-light is-fullwidth"
          >
            {{ $gettext("Anemia") }}
          </router-link>
        </div>

        <div
          v-if="site.earlyidEnabled && ageInMonths < 10 * 12 && ageInMonths > 1"
          class="column"
        >
          <router-link
            :to="{ name: 'ChildConfirmation', params: { siteCmiId: site.cmiId, childId, assessmentType: 'd' } }"
            class="button is-primary is-fullwidth"
          >
            {{ $gettext("Developmental Screening") }}
          </router-link>
        </div>
      </div>
    </template>
  </article>
</template>
<style scoped lang="scss">
</style>
