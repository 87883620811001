import dayjs from "dayjs"

/*
 * ASSESSMENT CALENDAR UTILS
 *
 */

/*
 * Given a year and month, return all of the days in the month.
 * @args {number}    year -- year to find the previous partial week
 * @args {number}    month -- month to find the previous partial week
 * @return [Array] Dayjs objects
 */
function getDaysInMonthForYear(year: number, month: number) {
  const date = dayjs(`${year}-${month + 1}-01`)
  const daysInMonth = []
  const daysInMonthCount = date.daysInMonth()
  for (let day = 1; day <= daysInMonthCount; day++) {
    daysInMonth.push(date.date(day))
  }

  return daysInMonth
}

/*
 * Given a year and month, calculate the partial week days for the previous month.
 * @args {number}    year -- year to find the previous partial week
 * @args {number}    month -- month to find the previous partial week
 * @return [Array] Dayjs objects
 */
function findPartialWeekOfPreviousMonth(year: number, month: number) {
  const daysInMonth = getDaysInMonthForYear(year, month - 1)
  const daysInMonthCount = daysInMonth[0].daysInMonth()
  // check if last day of month is a Sunday. Then there are no partial days to account for
  const lastDay = daysInMonth[daysInMonthCount - 1].day()
  if (lastDay === 0) {
    return []
  }
  // days to grab by doing last day - 1 (1 is Monday's value)
  const daysToGrab = lastDay - 1
  // grab all of the days between the day and the prior monday
  const days = daysInMonth.slice(daysInMonthCount - 1 - daysToGrab)

  return days
}

/*
 * Given a year and month, calculate the partial week days for the next month.
 * if first day is 0 (sunday) we want to return an array of length 1
 * if first day is 1 (monday) we want to return an empty array since there are no partial days
 * if first day is 2 (tuesday) we want to return an array of length 6
 * if first day is 3 (wednesday) we want to return an array of length 5
 * if first day is 4 (thursday) we want to return an array of length 4
 * if first day is 5 (friday) we want to return an array of length 3
 * if first day is 6 (saturday) we want to return an array of length 2
 * @args {number}    year -- year to find the next partial week
 * @args {number}    month -- month to find the next partial week
 * @return [Array] Dayjs objects
 */
function findPartialWeekOfNextMonth(year, month) {
  const daysInMonth = getDaysInMonthForYear(year, month + 1)
  // check if first day of month is a Monday. Then there are no partial days to account for
  const firstDay = daysInMonth[0].day()
  if (firstDay === 1) {
    return []
  }
  const daysToGrab = firstDay === 0 ? 1 : 8 - firstDay
  const days = daysInMonth.slice(0, daysToGrab)

  return days
}

/*
 * Given an array of days in a calendar month split them into arrays of 7 days for each week
 * @args {Array}    calendar -- array of Dayjs objects
 * @return [Array][Array] Dayjs objects
 */
export function convertCalendarToWeeks(calendar) {
  return Array.from({ length: Math.ceil(calendar.length / 7) }, (_, i) =>
    calendar.slice(i * 7, (i + 1) * 7),
  )
}

/*
 * Given a year and month, return all of the calendar days in the month.
 * This accounts for days of the previous and next month to fill in any gaps in the calendar.
 * The calendar data starts on a Monday and ends on a Sunday (Mo, Tu, We, Th, Fr, Sa, Su)
 * @args {number}    year -- year to use to get the full calendar month
 * @args {number}    month -- month to use to get the full calendar month (between 0 and 11)
 * @return [Array] Dayjs objects
 */
export function getCalendar(year, month) {
  return findPartialWeekOfPreviousMonth(year, month)
    .concat(getDaysInMonthForYear(year, month))
    .concat(findPartialWeekOfNextMonth(year, month))
}
