<script lang="ts" setup>
import { ref } from "vue"
import { useRoute } from "vue-router"
import dayjs from "dayjs"

import { getChildById, finalizeChild } from "@/services/Child"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { getDischargeChildSchema } from "@/schemas/Child"
import { getReasonForDischargeOptionsResidential, getTransferSites } from "@/utils/Options"

import OfflineEnabledForm from "@/components/OfflineEnabledForm.vue"
import ChildHeader from "@/components/children/ChildHeader.vue"

const route = useRoute()
const childId = parseInt(route.params.childId as string)
const siteCmiId = parseInt(route.params.siteCmiId as string)
const successRoute = { name: "ChildList", params: { siteCmiId } }
let dischargeChildSchema : Array<object>

const existingChild = ref({})
const formIsReady = ref(false)

function mungeData(child: object) {
  if (child.dateOfDischarge) {
      child.dateOfDischarge = dayjs(child.dateOfDischarge).toDate()
    }
    if (child.dateOfDeath) {
      child.dateOfDeath = dayjs(child.dateOfDeath).toDate()
    }
    child.siteId = siteCmiId
    child.id = childId
}

async function getData() {
  const child = await getChildById(childId)
  const transferSites = await getTransferSites(await child.getSite())
  dischargeChildSchema = getDischargeChildSchema(child, transferSites)
  const dateFieldNames = ["dateOfDischarge", "dateOfDeath"]
  dateFieldNames.forEach(f => {
    if (child[f]) {
      child[f] = dayjs(child[f]).format("YYYY-MM-DD")
    }
    else {
      child[f] = dayjs().format("YYYY-MM-DD")
    }
  })
  if (child.typeOfService == "residential") {
    dischargeChildSchema.forEach(field => {
      if (field["id"] == "reasonForDischarge") {
        field["options"] = getReasonForDischargeOptionsResidential()
      }
    })
  }
  existingChild.value = child
  setCurrentViewContext(child, "child")
  formIsReady.value = true
}

async function localSaveFunc(data: object) {
  let type = "childDischarge"
  if (data["transferSite"]) {
    data["oldSiteId"] = data["siteId"] // For API call later.
    data["siteId"] = data["transferSite"]
    delete data["transferSite"]
    // This is hacky, but in order for the child to appear correctly in the new
    // site, we don't want them to have a discharge date. We'll handle the syncing
    // of this to the server with the transferChild logic.
    // NOTE that transferred children won't show up in the discharged list for their
    // old site. (Since our db isn't relational and site membership data live directly
    // on the child, it gets messy to have multiple Child > Site connections.)
    data["dateOfAdmission"] = data["dateOfDischarge"]
    delete data["dateOfDischarge"]
    type = "childTransfer"
  }
  return await finalizeChild(data, type)
}

getData()
</script>

<template>
  <article>
    <OfflineEnabledForm
      v-if="formIsReady"
      :page-title="$gettext('Discharge child')"
      :schema="dischargeChildSchema"
      :form-data="existingChild"
      :local-save-func="localSaveFunc"
      :munge-form-data-func="mungeData"
      :success-route="successRoute"
    >
      <template #header>
        <ChildHeader :child="existingChild" />
      </template>
    </OfflineEnabledForm>
  </article>
</template>
