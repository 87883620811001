<template>
  <section class="mt-5">
    <strong>{{ account.name }}</strong> <span class="muted">({{ account.username }})</span>
    <div
      v-for="role in roles"
      :key="role.value"
      class="mb-2"
    >
      <button
        :class="{
          button: true,
          'is-primary': true,
          'is-loading': clickedButton == `add-${role.value}-${account.accountId}`,
        }"
        @click.prevent="addWithRole(account.id, role.value)"
      >
        {{ role.addLabel }}
      </button>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import { ref } from "vue"
  import { gettext } from "@/utils/Translation"

  import { showFlashMessage } from "@/utils/GlobalState"
  import { linkAccountToSite } from "@/services/Site"
  import { linkAccountToProject } from "@/services/Project"
  import { getSiteRolesOptions, getProjectRolesOptions } from "@/utils/Options"

  const { $gettext } = gettext
  const emit = defineEmits(["accountLinked"])
  const clickedButton = ref(null)

  const props = defineProps({
    account: {
      type: Object,
      required: true,
    },
    // One of the next two must be supplied. It also signals
    // whether we're dealing with a project or site.
    siteCmiId: {
      type: Number,
      default: null,
    },
    projectCmiId: {
      type: Number,
      default: null,
    },
  })

  const isInSiteContext = Boolean(props.siteCmiId)

  const roles = isInSiteContext ? getSiteRolesOptions() : getProjectRolesOptions()

  async function addWithRole(accountId, role) {
    clickedButton.value = `add-${role}-${accountId}`
    const linkAccount = isInSiteContext ? linkAccountToSite : linkAccountToProject
    const contextId = isInSiteContext ? props.siteCmiId : props.projectCmiId
    await linkAccount(accountId, contextId, role).catch((msg) => {
      showFlashMessage({ msg, class: "is-warning" })
    })
    showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
    emit("accountLinked")
  }
</script>
<style scoped>
  .button {
    width: 100%;
  }
</style>
